// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/stefcoenen/Projects/attorno/attorno-gatsby/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bedankt-js": () => import("/Users/stefcoenen/Projects/attorno/attorno-gatsby/src/pages/bedankt.js" /* webpackChunkName: "component---src-pages-bedankt-js" */),
  "component---src-pages-bedrijfsfeesten-js": () => import("/Users/stefcoenen/Projects/attorno/attorno-gatsby/src/pages/bedrijfsfeesten.js" /* webpackChunkName: "component---src-pages-bedrijfsfeesten-js" */),
  "component---src-pages-contacteer-ons-js": () => import("/Users/stefcoenen/Projects/attorno/attorno-gatsby/src/pages/contacteer-ons.js" /* webpackChunkName: "component---src-pages-contacteer-ons-js" */),
  "component---src-pages-faq-js": () => import("/Users/stefcoenen/Projects/attorno/attorno-gatsby/src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-foodtruck-feesten-js": () => import("/Users/stefcoenen/Projects/attorno/attorno-gatsby/src/pages/foodtruck-feesten.js" /* webpackChunkName: "component---src-pages-foodtruck-feesten-js" */),
  "component---src-pages-index-js": () => import("/Users/stefcoenen/Projects/attorno/attorno-gatsby/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-media-js": () => import("/Users/stefcoenen/Projects/attorno/attorno-gatsby/src/pages/media.js" /* webpackChunkName: "component---src-pages-media-js" */),
  "component---src-pages-mobiele-pizzabus-js": () => import("/Users/stefcoenen/Projects/attorno/attorno-gatsby/src/pages/mobiele-pizzabus.js" /* webpackChunkName: "component---src-pages-mobiele-pizzabus-js" */),
  "component---src-pages-recensies-js": () => import("/Users/stefcoenen/Projects/attorno/attorno-gatsby/src/pages/recensies.js" /* webpackChunkName: "component---src-pages-recensies-js" */),
  "component---src-pages-waarom-attorno-js": () => import("/Users/stefcoenen/Projects/attorno/attorno-gatsby/src/pages/waarom-attorno.js" /* webpackChunkName: "component---src-pages-waarom-attorno-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/stefcoenen/Projects/attorno/attorno-gatsby/.cache/data.json")

